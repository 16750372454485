import {httpClient} from "@/app/shared/shared-services/httpClient";
import {environment} from "@/environment";
import {CategoryModel} from "@/app/ecommerce/services/category-service/interfaces/category.model";
import {CreateCategoryRequestDto} from "@/app/ecommerce/services/category-service/interfaces/create-category-request.dto";
import {UpdateCategoryRequestDto} from "@/app/ecommerce/services/category-service/interfaces/update-category-request.dto";

const URL = `${environment.ecommerceApiBaseUrl}category`;
export const CategoryService = {
  async getAll(): Promise<CategoryModel[]> {
    return (await httpClient.get(URL)).data.data;
  },
  async storeCategory(category: CategoryModel): Promise<CategoryModel> {
    const categoryReqDto = this.toCreateCategoryReqDto(category);
    return (await httpClient.post(URL, categoryReqDto)).data.data;
  },
  async updateCategory(category: CategoryModel): Promise<CategoryModel> {
    const categoryReqDto = this.toUpdateCategoryReqDto(category);
    return (await httpClient.post(`${URL}/${categoryReqDto.id}?_method=PUT`, categoryReqDto)).data.data;
  },
  async deleteCategory(categoryId: string): Promise<void> {
    return httpClient.delete(`${URL}/${categoryId}`);
  },

  toCreateCategoryReqDto(categoryModel: CategoryModel): CreateCategoryRequestDto {
    return {
      name: categoryModel.name,
      category_parent_id: categoryModel.category_parent?.id,
      store_id: categoryModel.store.id!,
      hidden: categoryModel.hidden,
      cover_url: categoryModel.cover_url
    };
  },

  toUpdateCategoryReqDto(categoryModel: CategoryModel): UpdateCategoryRequestDto {
    return {
      id: categoryModel.id!,
      name: categoryModel.name,
      category_parent_id: categoryModel.category_parent?.id,
      store_id: categoryModel.store.id!,
      hidden: categoryModel.hidden,
      cover_url: categoryModel.cover_url
    };
  }
};
