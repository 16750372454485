



































































































































































import AgTable from '@/app/shared/shared-components/ag-grid/AgTable.vue';
import AgTableBtnCell from "@/app/shared/shared-components/ag-grid/AgTableBtnCell.vue";
import AgTableImageCell from '@/app/shared/shared-components/ag-grid/AgTableImageCell.vue';
import vSelect from 'vue-select';
import utilities from '@/app/shared/utilities/index';
import FilePondComponent from "@/app/shared/shared-components/FilePondComponent.vue";
import {ColDef, GridApi, GridOptions} from "ag-grid-community";
import {CategoryModel} from "@/app/ecommerce/services/category-service/interfaces/category.model";
import Vue from "vue";
import {StoreModel} from "@/app/ecommerce/services/store-service/interfaces/store.model";
import {mapActions, mapState} from "pinia";
import {useCategoryStore} from "@/app/ecommerce/views/Categories/category.store";
import {useStoreStore} from "@/app/ecommerce/views/Stores/store.store";

export default Vue.extend({
  name: "Category",
  components: {
    AgTable,
    vSelect,
    FilePondComponent
  },
  data() {
    return {
      gridApi: {} as GridApi | null | undefined,
      isLoading: true,
      gridOptions: {} as GridOptions,
      popupActiveDetails: false,
      popupActive: false,
      dialogTitle: '',
      stores: [],
      category: {
        id: undefined,
        store: {} as StoreModel,
        category_parent: {} as CategoryModel
      } as CategoryModel,
    };
  },
  computed: {
    ...mapState(useCategoryStore, ['getCategories']),
    ...mapState(useStoreStore, ['getStores']),
  },
  methods: {
    ...mapActions(useCategoryStore, ['fetchCategories', 'addCategory', 'editCategory', 'deleteCategory']),
    ...mapActions(useStoreStore, ['fetchStores']),
    createColDefs(): Array<ColDef> {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          width: 225,
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: 'الاسم',
          field: 'name',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'اسم المحل',
          field: 'store.name',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الشعار',
          field: 'cover_url',
          cellRendererFramework: AgTableImageCell
        },
        {
          headerName: 'عدد المنتجات',
          field: 'sub_product_count',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            deleteRecord: function (id: number, rowIndex: number) {
              self.deleteCategory(id, rowIndex);
            },
            editRecord: function (category: CategoryModel) {
              self.editRecord(category);
            },
            viewRecordDetails: function (category: CategoryModel) {
              self.viewRecordDetails(category);
            },
            actions: ['view', 'edit', 'delete']
          },
        },
      ];
    },
    viewRecordDetails(category: CategoryModel) {
      this.popupActiveDetails = true;
      this.category = category;
    },
    fileUploaded(file: string) {
      this.category.cover_url = file;
    },
    fileDeleted() {
      this.category.cover_url = '';
    },
    rerender() {
      this.gridApi?.setRowData(this.getCategories);
    },
    deleteCategories(ids: Array<number[]>) {
      this.deleteCategory(ids[0], undefined);
    },
    openPopup() {
      (this.$refs.validationObserver as any)?.reset();
      this.popupActive = true;
    },
    createRecord() {
      utilities.initObjectMembers(this.category);
      this.dialogTitle = 'اضافة صنف منتج';
      this.openPopup();
    },
    editRecord(category: CategoryModel) {
      this.dialogTitle = 'تعديل صنف منتج';
      Object.assign(this.category, category);
      this.category.store = category.store;
      this.category.category_parent = category.category_parent;
      this.openPopup();
    },
    async onSubmit() {
      this.isLoading = true;
      if (this.category.id !== undefined) {
        await this.editCategory(this.category);
      } else {
        await this.addCategory(this.category);
      }
      this.rerender();
      this.popupActive = false;
      this.isLoading = false;
    },
  },
  async created() {
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    await Promise.all([this.fetchCategories(), this.fetchStores()]);
    this.isLoading = false;
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
});
