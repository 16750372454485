import {defineStore} from 'pinia';
import {CategoryModel} from '@/app/ecommerce/services/category-service/interfaces/category.model';
import {CategoryService} from '@/app/ecommerce/services/category-service/category.service';
import {isArray} from "lodash";
import category from "@/app/ecommerce/views/Categories/Category.vue";

export const useCategoryStore = defineStore('category', {
  state() {
    return {
      categories: [] as CategoryModel[]
    };
  },
  getters: {
    getCategories(state: { categories: CategoryModel[] }) {
      return state.categories;
    }
  },
  actions: {
    async fetchCategories() {
      this.categories = await CategoryService.getAll();
    },

    async addCategory(category: CategoryModel) {
      const createdCategory = await CategoryService.storeCategory(category);
      this.categories.unshift(createdCategory);
    },

    async editCategory(category: CategoryModel) {
      const updatedCategory = await CategoryService.updateCategory(category);
      let index = this.categories.map((m) => {
        return m.id;
      }).indexOf(updatedCategory.id);
      this.categories[index] = updatedCategory;
    },

    async deleteCategory(ids: number | number [], index?: number) {
      await CategoryService.deleteCategory(isArray(ids) ? ids.join(',') : ids.toString());
      if (isArray(ids)) {
        this.categories = this.categories.filter(category => !ids.includes(category.id!));
      } else {
        this.categories.splice(index!, 1);
      }
    }
  }
});
